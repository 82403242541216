export const VALID_PROJECT_ACTIONS = {"save_for_later":"save_for_later","submit_for_approval":"submit_for_approval","next_step":"next_step"};
export const HONEYBADGER_CONFIG = {
  apiKey: "",
  environment: "production",
  ignorePatterns: /ResizeObserver loop limit exceeded/i,
  revision: 'master',
  disabled: false,
}
export const MINIMUM_PASSWORD_LENGTH = 10;
export const MIN_HOURLY_RATE = 20;
export const MIN_HOURLY_RATE_FOR_CHANNEL = 1;
export const FREE_EMAILS_ARRAY = ["gmail.com","yahoo.com","hotmail.com","outlook.com","aol.com","icloud.com","comcast.com"];
export const MAX_HOURS_PER_WEEK = 60;
export const MAX_PROJECT_MONTHS = 36;
export const MIN_HOURS_PER_WEEK = 10;
export const MIN_PROJECT_MONTHS = 1;
