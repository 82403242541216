import ReactiveRecord, { Model } from 'reactiverecord';

class AppSettings extends Model {
  static store = {
    singleton: true,
  };

  static routes = {
    index: ':prefix/app_settings',
  };
  static schema = {
    access_job_processing: Boolean,
    bid_autocomplete: Boolean,
    enterprise_sign_up: Boolean,
    external_bid_status: Boolean,
    iam: Boolean,
    jobpost_helper_boxes: Boolean,
    jobs_spellcheck: Boolean,
    join_ex_tx: Boolean,
    nylas_login_details: Object,
    opt_in_to_recruit_modal: Boolean,
    project_removal: Boolean,
    public_community_page: Boolean,
    public_jobs_page: Boolean,
    recommended_talent: Boolean,
    resume_extraction_v1: Boolean,
    show_invite_dtc_talent: Boolean,
    show_talent_clouds_ui: Boolean,
    subscriber_logo: Boolean,
    talent_freelancer_pro: Boolean,
    use_project_search_microservice_for_search: Boolean,
    video_calls: Boolean,
    extend_offer_improvement: Boolean,
  };

  static allCachedListeners = [];
  static async allCached() {
    if (this.allCachedResult) {
      return this.allCachedResult;
    }
    if (this.allCachedRequestedAtLeastOnce) {
      return await new Promise(resolve =>
        this.allCachedListeners.push(resolve),
      );
    }
    this.allCachedRequestedAtLeastOnce = true;
    this.allCachedResult = await this.all();
    this.allCachedListeners.forEach(listener => listener(this.allCachedResult));
    return this.allCachedResult;
  }
}

export default ReactiveRecord.model('AppSettings', AppSettings);
