import { useState, useEffect } from 'react';
import { noop, showMessage } from 'utilities';
import { REACT_QUERY_STATUSES, VALID_PROJECT_ACTIONS } from 'consts';
import http from 'utilities/http';
import Honeybadger from 'honeybadger-js';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const CACHE_FOR_ONE_DAY = 1000 * 60 * 60 * 24;
const CACHE_FOR_ONE_HOUR = 1000 * 60 * 60;

export async function callCandidateRequest(appointmentId) {
  const response = await http.get(
    `/employer/calls/new?appointment=${appointmentId}&call_now=true`,
  );
  const data = await response.json();
  return data;
}

export const useCancelAppointmentMutation = (
  cancelUrl,
  onSuccess = noop,
  onError = noop,
) =>
  useMutation({
    mutationFn: async () => {
      const response = await http.delete(cancelUrl, {
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        referrer: 'no-referrer',
      });
      const result = await response.json();

      if (response.ok) {
        return result;
      }
      return Promise.reject(result);
    },
    onSuccess: onSuccess,
    onError: onError,
  });

export async function confirmAppointment(updatedValues) {
  const response = await http.patch(
    `/api/v1/moms/appointments/${updatedValues.id}`,
    { body: JSON.stringify(updatedValues) },
  );
  const data = await response.json();
  return data;
}

export const useBid = requestLink => {
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    http
      .get(requestLink)
      .then(res => res.json())
      .then(response => {
        setResponse(response);
        setLoading(false);
      })
      .catch(error => {
        console.log(error);
        showMessage(`An error has occurred: ${error}`, true);
        setLoading(false);
      });
  }, [requestLink]);
  return { response, loading };
};

export const useRequestBudgetCalculation = requestURL => {
  const [budget, setBudget] = useState(null);
  const [loading, setLoading] = useState(true);
  // Prevents setting the state when the component unmounts
  let mounted = true;
  useEffect(() => {
    setLoading(true);
    http
      .get(requestURL)
      .then(res => res.json())
      .then(response => {
        if (mounted) {
          setBudget(response);
          setLoading(false);
        }
      })
      .catch(
        error => {
          showMessage(`An error has occurred: ${error}`, true);
          setLoading(false);
        },
        [requestURL],
      );
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestURL]);

  return { budget, loading };
};

export const useSaveProject = (requestURL, handleAfterSubmit) => {
  const [loading, setLoading] = useState(false);
  const handleSubmit = e => {
    e.persist();
    setLoading(true);
    const project = {
      project_action: e.target.name,
    };
    http
      .patch(requestURL, { body: JSON.stringify(project) })
      .then(response => {
        return response.json();
      })
      .then(function () {
        setLoading(false);
        const isSaveForLater =
          e.target.name === VALID_PROJECT_ACTIONS.save_for_later;
        handleAfterSubmit(isSaveForLater);
      })
      .catch(error => {
        setLoading(false);
        showMessage(`An error has occurred: ${error}`, true);
      });
  };
  return { loading, handleSubmit };
};

export const getEmsiSkills = async skill => {
  const queryParamSafeSkill = encodeURIComponent(skill);
  const response = await http.get(
    `/api/v1/emsi_skills/search?emsi_skill=${queryParamSafeSkill}`,
  );
  return await response.json();
};

export const extractEmsiSkills = async text => {
  const response = await http.post(`/api/v1/emsi_skills/extract`, {
    credentials: 'same-origin',
    referrer: 'no-referrer',
    body: JSON.stringify({ text: text }),
  });
  const data = await response.json();
  return data;
};

export const getSuggestedEmsiSkills = async skillIds => {
  const response = await http.get(
    `/api/v1/emsi_skills/suggest?ids=${skillIds}`,
  );
  return await response.json();
};

export const getEmsiJobTitles = async query => {
  const paramSafeQuery = encodeURIComponent(query);
  const response = await http.get(
    `/api/v1/titles/search?query=${paramSafeQuery}&category=specific`,
  );
  const data = await response.json();
  return data.titles;
};

export const confirmAppointmentDate = async (appointment, appointmentDate) => {
  const url = `/api/v1/moms/appointments/${appointment}/appointment_dates/${appointmentDate}`;
  const response = await http.patch(url, {
    credentials: 'same-origin',
    referrer: 'no-referrer',
  });
  const data = await response.json();
  return data;
};

export const saveBidForLater = async (projectId, bidId) => {
  const body = JSON.stringify({ bid_action: 'save_for_later' });
  const url = `/api/v1/employers/projects/${projectId}/bids/${bidId}`;
  const response = await http.patch(url, {
    body,
    credentials: 'same-origin',
    referrer: 'no-referrer',
  });
  const data = await response.json();
  return data;
};

export const createSchedulingPage = async fields => {
  const url = '/api/v1/employers/interview_scheduling_pages';
  const response = await http.post(url, {
    credentials: 'same-origin',
    referrer: 'no-referrer',
    body: JSON.stringify(fields),
  });
  const data = await response.json();
  return data;
};

export const updateSchedulingPage = async fields => {
  const url = `/api/v1/employers/interview_scheduling_pages/${fields.id}`;
  const response = await http.patch(url, {
    credentials: 'same-origin',
    referrer: 'no-referrer',
    body: JSON.stringify(fields),
  });
  const data = await response.json();
  return data;
};

export const deleteSchedulingPage = async fields => {
  const url = `/api/v1/employers/interview_scheduling_pages/${fields.id}`;
  await http.delete(url, {
    credentials: 'same-origin',
    referrer: 'no-referrer',
    body: JSON.stringify(fields),
  });
  // Returns nothing - 204 NO CONTENT
};

export const updateDismissedCalendarModalCount = async count => {
  const url = '/api/v1/user/account';
  const response = await http.patch(url, {
    credentials: 'same-origin',
    referrer: 'no-referrer',
    body: JSON.stringify({ dismissed_calendar_modal_count: count }),
  });
  const data = await response.json();
  if (data.errors) {
    Honeybadger.notify(
      `[updateDismissedCalendarModalCount] - error updating the dismissed calendar modal count`,
    );
  }
};

export const trackBidSearchAction = async (
  projectSlug,
  searchParams,
  name,
  targetId,
  searchResult,
) => {
  const url = `/api/v1/employers/projects/${projectSlug}/bids/track_event`;
  const response = await http.post(url, {
    credentials: 'same-origin',
    referrer: 'no-referrer',
    body: JSON.stringify({
      ...searchParams,
      ...searchResult,
      event_type: name,
      target_action_id: targetId,
    }),
  });
  const data = await response.json();
  return data;
};

export const trackProjectSearchAction = async (
  urlPrefix,
  searchParams,
  eventName,
  targetId,
  searchResult,
) => {
  const url = `${urlPrefix}/track_event`;
  const response = await http.post(url, {
    credentials: 'same-origin',
    referrer: 'no-referrer',
    body: JSON.stringify({
      ...searchParams,
      ...searchResult,
      event_type: eventName,
      target_action_id: targetId,
    }),
  });
  const data = await response.json();
  return data;
};

export const useCurrentUser = () =>
  useQuery({
    queryKey: ['currentUser'],
    queryFn: () =>
      http.get('/api/v1/user/account').then(response => response.json()),
    staleTime: CACHE_FOR_ONE_HOUR,
  });

export const useProject = projectSlug =>
  useQuery({
    queryKey: ['project', projectSlug],
    queryFn: () =>
      http
        .get(`/api/v1/employers/projects/${projectSlug}`)
        .then(response => response.json()),
  });

export const useCurrentEmployer = () =>
  useQuery({
    queryKey: ['currentEmployer'],
    queryFn: () =>
      http.get('/api/v1/employers/account').then(response => response.json()),
    placeholderData: {
      legal_company_name: '',
      business_website: '',
      corporation_type: '',
      state_of_inc: '',
      legal_address: '',
      legal_city: '',
      legal_state: '',
      legal_zip: '',
    },
    staleTime: CACHE_FOR_ONE_HOUR,
  });

export const useCurrentSavedJobs = () =>
  useQuery({
    queryKey: ['currentSavedJobs'],
    queryFn: () =>
      http
        .get('/api/v1/moms/saved_jobs')
        .then(response => response.json())
        .catch(() =>
          showMessage(
            "We're sorry, but an error has occurred. Please try again later.",
            true,
          ),
        ),
  });

export const useCurrentUserMutation = () =>
  useMutation({
    mutationFn: async params => {
      const response = await http.put('/api/v1/user/account', {
        body: JSON.stringify({ ...params }),
      });
      const result = await response.json();

      if (response.ok) {
        return result;
      }
      return Promise.reject(result);
    },
  });

export const useCloseProjectMutation = slug =>
  useMutation({
    mutationFn: async values => {
      const response = await http.patch(
        `/api/v1/employers/projects/${slug}/close`,
        {
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          referrer: 'no-referrer',
          body: JSON.stringify({ reason: values.reason }),
        },
      );

      if (response.ok) {
        return response.json();
      }
      return Promise.reject(null);
    },
  });

export const useProjectCreation = () =>
  useMutation({
    mutationFn: async params => {
      const response = await http.post(`/api/v1/employers/projects`, {
        body: JSON.stringify({ ...params }),
      });
      const result = await response.json();
      if (response.ok) {
        return result;
      }
      return Promise.reject(result);
    },
  });

export const useCurrentEmployerMutation = () =>
  useMutation({
    mutationFn: async params => {
      const response = await http.put(`/api/v1/employers/account`, {
        body: JSON.stringify(params),
      });
      const result = response;
      if (response.ok) {
        return result;
      }
      return Promise.reject(result);
    },
  });

export const useProjectMutation = (projectSlug, onSuccess = noop) =>
  useMutation({
    mutationFn: async params => {
      const response = await http.put(
        `/api/v1/employers/projects/${projectSlug}`,
        {
          body: JSON.stringify({ ...params }),
        },
      );
      const result = await response.json();

      if (response.ok) {
        return result;
      }
      return Promise.reject(result);
    },
    onSuccess: onSuccess,
  });

export const useSaveAsDraft = projectSlug =>
  useMutation({
    mutationFn: async () => {
      const response = await http.put(
        `/api/v1/employers/projects/${projectSlug}`,
        {
          body: JSON.stringify({
            project_action: VALID_PROJECT_ACTIONS.save_for_later,
          }),
        },
      );
      const result = await response.json();

      if (response.ok) {
        return result;
      }

      return Promise.reject(result);
    },
  });

export const useExtractedSkills = (text, onSuccess) =>
  useQuery({
    queryKey: ['project', 'skillsDescription', text],
    queryFn: () =>
      http
        .post(`/api/v1/emsi_skills/extract`, {
          credentials: 'same-origin',
          referrer: 'no-referrer',
          body: JSON.stringify({ text: text }),
        })
        .then(response => response.json()),
    enabled: !!text,
    onSuccess: onSuccess,
  });

export const useEmsiSkills = (keyword, options) =>
  useQuery({
    queryKey: ['skillSearch', { keyword }],
    queryFn: () => {
      const queryParamSafeSkill = encodeURIComponent(keyword);
      return http
        .get(`/api/v1/emsi_skills/search?emsi_skill=${queryParamSafeSkill}`)
        .then(response => response.json());
    },
    enabled: !!keyword,
    ...options,
  });

export const useCapabilitiesCategories = () =>
  useQuery({
    queryKey: ['capabilitiesCategories'],
    queryFn: () =>
      http
        .get('/api/v1/capabilities-categories')
        .then(response => response.json()),
    staleTime: CACHE_FOR_ONE_DAY, // cache for 1 day since these are not expected to change
  });

export const useCapabilities = options =>
  useQuery({
    queryKey: ['capabilities'],
    queryFn: () =>
      http.get('/api/v1/capabilities').then(response => response.json()),
    staleTime: CACHE_FOR_ONE_DAY, // cache for 1 day since these are not expected to change
    ...options,
  });

export const useIndustries = () =>
  useQuery({
    queryKey: ['industries'],
    queryFn: () =>
      http.get('/api/v1/industries').then(response => response.json()),
    staleTime: CACHE_FOR_ONE_DAY, // cache for 1 day since these are not expected to change
  });
/**
 * Calls the employer_dashboard API for the data with the most recent activity for the employer
 * @returns {Object} response:
 *  appointment_count: Number
 *  company_name: String,
 *  current_user_can_invite: Boolean,
 *  dismissed_calendar_modal_count: Number,
 *  display_get_started_block: Boolean,
 *  email: String,
 *  is_ghosting: Boolean,
 *  is_nylas_user: Boolean,
 *  is_subscriber: Boolean,
 *  projects_count: Number,
 *  projects: Array,
 *  upcoming_appointments: Array,
 *  user_first_name: String,
 *  user_id: Number,
 *  show_profile_completion: Boolean,
 */
export const useEmployerDashboard = () =>
  useQuery({
    queryKey: ['employerDashboard'],
    queryFn: () =>
      http.get('/api/v1/employers/dashboard').then(response => response.json()),
  });

export const useProjects = () =>
  useQuery({
    queryKey: ['projects'],
    queryFn: () =>
      http.get('/api/v1/employers/projects').then(response => response.json()),
  });

export const useEmployerProject = projectId =>
  useQuery({
    queryKey: ['project', projectId],
    queryFn: () =>
      http
        .get(`/api/v1/employers/projects/${projectId}`)
        .then(response => response.json()),
  });

export const useEmployerCollaborators = enabled =>
  useQuery({
    queryKey: ['collaborators'],
    queryFn: () =>
      http.get(`/api/v1/employers/users`).then(response => response.json()),
    enabled,
    staleTime: CACHE_FOR_ONE_HOUR,
  });

export const useAppSettingsRequest = () =>
  useQuery({
    queryKey: ['appSettings'],
    queryFn: () =>
      http.get('/api/v1/app_settings').then(response => response.json()),
  });

export const useEmployerUserMutation = employerUserId =>
  useMutation({
    mutationFn: async ({ admin }) => {
      const response = await http.put(
        `/api/v1/employers/users/${employerUserId}`,
        {
          body: JSON.stringify({ admin }),
        },
      );
      const result = await response.json();

      if (response.ok) {
        return result;
      }
      return Promise.reject(result);
    },
  });

export const useInvites = enabled =>
  useQuery({
    queryKey: ['invites'],
    queryFn: () =>
      http.get('/api/v1/employers/invites').then(response => response.json()),
    enabled: enabled,
  });

export const useInviteCreation = () =>
  useMutation({
    mutationFn: async ({ first_name, last_name, email }) => {
      const response = await http.post(`/api/v1/employers/invites`, {
        body: JSON.stringify({ first_name, last_name, email }),
      });
      const result = await response.json();
      if (response.ok) {
        return result;
      }
      return Promise.reject(result);
    },
  });

export const useRecommendedTalent = (
  projectId,
  appSettingEnabled,
  onSuccess = noop,
) =>
  useQuery({
    queryKey: ['recommendedTalent', projectId],
    queryFn: () =>
      http
        .get(
          `/api/v1/employers/projects/${projectId}/recommended_moms?count_only=true`,
        )
        .then(response => response.json()),
    enabled: appSettingEnabled,
    staleTime: CACHE_FOR_ONE_HOUR,
    onSuccess: onSuccess,
    // Handle errors like there are no recommendations
    select: response => {
      const { data, error } = response;
      if (!data || error) {
        return { data: { paging: { count: 0, total: 0, records: [] } } };
      }
      return response;
    },
  });

/**
 * Queries the /recommended_moms?count_only=true which doesn't actually call the Nerds API.
 * Instead it returns either a total of 5 or 0 if endpoint params are valid.
 * We do this so that we don't have to wait on /recommended_moms NERDS API response
 * This is used primarily in 3 places: The bids page (banner and handling empty states), JobLiveModal, and ProjectSubmittedModal
 * @param {String} projectId - Project slug
 * @param {Boolean} appSettingEnabled - value coming from the app setting. Determines if the feature is enabled for the employer
 * @returns [recommendedTalents, showRecommendedTalent, isLoading]
 */
export const useRecommendedTalentQuery = (projectId, appSettingEnabled) => {
  const { data: recommendedTalents, status: recommendedTalentQueryStatus } =
    useRecommendedTalent(projectId, appSettingEnabled);
  const isLoading =
    appSettingEnabled &&
    recommendedTalentQueryStatus === REACT_QUERY_STATUSES.LOADING;
  const showRecommendedTalent =
    appSettingEnabled &&
    recommendedTalents?.data?.paging &&
    recommendedTalents?.data.paging.total > 0;

  return [showRecommendedTalent, isLoading];
};

export const useAgreements = () =>
  useQuery({
    queryKey: ['agreements'],
    queryFn: () =>
      http.get(`/api/v1/agreements`).then(response => response.json()),
  });

export const useFeedbackForm = formName =>
  useQuery({
    queryKey: ['feedbackForm', formName],
    queryFn: () =>
      http
        .get(`/api/v1/feedback-forms/${formName}`)
        .then(response => response.json()),
  });

export const useShowDemographicSurvey = enabled =>
  useQuery({
    queryKey: ['showDemographicSurvey'],
    queryFn: () =>
      http
        .get(`/api/v1/moms/demographic_surveys/show_survey`)
        .then(response => response.json()),
    enabled: enabled,
  });

export const useGetProjectBidMatches = (projectId, bidId) =>
  useQuery({
    queryKey: ['getProjectBidMatches'],
    queryFn: () =>
      http
        .get(`/api/v1/employers/projects/${projectId}/bids/${bidId}/matches`)
        .then(response => response.json()),
  });

export const useCreateUtmSignup = () =>
  useMutation({
    mutationFn: async fields => {
      const response = await http.post('/api/v1/utm-signups', {
        body: JSON.stringify(fields),
      });
      const result = await response.json();
      if (response.ok) {
        return result;
      }
      return Promise.reject(result);
    },
  });

export const appSettingsQueryConfig = {
  queryKey: ['appSettings'],
  queryFn: () =>
    http.get('/api/v1/app_settings').then(response => response.json()),
  staleTime: CACHE_FOR_ONE_HOUR,
};

export const useAppSettingsRQ = () => {
  const queryClient = useQueryClient();
  const { data, status } = useQuery({
    ...appSettingsQueryConfig,
    initialData: () => {
      // ⬇️ look up the list cache for the app settings data
      return queryClient.getQueryData(['appSettings']);
    },
  });
  const isLoading = status === REACT_QUERY_STATUSES.LOADING;
  return { appSettings: data, isLoading };
};
