import { colors } from 'styleGuide';
import { SENIORITY_OPTIONS } from './containers/employer_application/projects/consts';

export const EXPERIENCE_LEVEL_ARRAY = [
  ['1 - 4 years experience', 'jr'],
  ['5 - 8 years experience', 'mid'],
  ['9+ years experience', 'sr'],
];

export const CORPORATION_TYPES = [
  'Limited Liability Corporation (LLC)',
  'Corporation',
  'Sole Proprietorship',
  'Non-Profit',
];

export const STATES = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District of Columbia', 'DC'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
];
export const HOURS_ARRAY = [[0], [10], [20], [30], [40]];
export const PREFERRED_HOURS = {
  label: 'How many hours per week can you work?*',
  fieldType: 'checkbox',
  fieldName: 'preferred_hours',
  options: {
    upto20: '1-20 hours per week',
    upto30: '21-30 hours per week',
    upto40: '31-40 hours per week',
    morethan40: '40 hours per week',
  },
};
export const ANALYTICS = {
  EVENT_TYPES: {
    Click: 'CLICK',
    Focus: 'FOCUS',
    Success: 'SUCCESS',
    Conversion: 'CONVERSION',
    Calculation: 'CALCULATION',
    Custom: 'CUSTOM',
    SMB_Conversion: 'SMB_Conversion',
    Enterprise_Conversion: 'Enterprise_Conversion',
    Talent_Conversion: 'Talent signup',
  },
  EVENT_CATEGORIES: {
    Company_Login: 'Company Login',
    Company_Create_Account: 'Company Create Account',
    Employer_Three_Page_Signup: 'Employer Three Page Signup',
    General_Join: 'General Join',
    Post_Job: 'Post a Job',
    Manage_Applicants: 'Manage Applicants',
  },
  USER_TYPES: {
    Employer: 'Employer',
    Talent: 'Talent',
  },
  USER_GROUPS: {
    timingAndBudgetVariants: [
      'Timing&Budget-Variant_A',
      'Timing&Budget-Variant_B',
      'Timing&Budget-Variant_C',
    ],
  },
};
export const ERROR_CODES = {
  INVALID_EMAIL: 'INVALID_EMAIL',
};
export const TRAVEL_OPTIONS = [
  ['Travel not required', 0],
  ['Up to 10%', 10],
  ['Up to 20%', 20],
  ['Up to 50%', 50],
  ['Up to 75%', 75],
  ['Up to 100%', 100],
];

export const PROJECT_REMOTE_OPTIONS = {
  fullyRemote: 'remote',
  inOffice: 'office',
  partially: 'combination',
};

export const REMOTE_DAYS_OPTIONS = [
  [1, 20],
  [2, 40],
  [3, 60],
  [4, 80],
  [5, 100],
];

export const FACEBOOK_SHARE_URL = 'https://www.facebook.com/sharer/sharer.php';
export const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';
export const LINKEDIN_SHARE_URL =
  'http://www.linkedin.com/shareArticle?mini=true';
export const TERMS_AND_CONDITIONS = '/terms_of_service';
export const MAX_PRIORITY_FUNCTIONS = 9;

export const redirectAfterParam = () => {
  if (
    typeof window === 'object' &&
    location.search &&
    location.search.indexOf('redirect_after=') > -1
  ) {
    return location.search.match(/redirect_after=([^&]*)/)[1];
  }
  return null;
};

const addRedirectAfterParam = originalPath => {
  const redirect_after = redirectAfterParam();
  return redirect_after
    ? `${originalPath}?redirect_after=${redirect_after}`
    : originalPath;
};

export const getRedirectLocation = () => {
  const redirect_after = redirectAfterParam();
  if (redirect_after) {
    const decodedURI = decodeURIComponent(redirect_after);
    try {
      const url = new URL(decodedURI);
      if (url) {
        return `${url.pathname}${url.search}`;
      }
    } catch (err) {
      return decodedURI;
    }
  }
  return false;
};

export const URLS = {
  about: '/about',
  applicationUrl: (projectLink, bidSlug = null) =>
    `${projectLink}/application${bidSlug && `/${bidSlug}`}`,
  calculateBudgetURL: '/employer/projects/calculate-budget?',
  careers: '/careers',
  careerGuidance: 'https://community.themomproject.com/the-study',
  customerSupport: 'https://help.themomproject.com',
  community: '/community',
  employerAppointments: '/employer/appointments',
  employerClouds: '/employer/clouds',
  employerCompanyInformation: '/employer/verification/step-one',
  employerCompanyAddress: '/employer/verification/step-two',
  employerCreate: '/employer/create',
  employerHome: '/employer',
  employerGetHelpURL:
    'https://meetings.hubspot.com/ashley-frazier/job-flow-creation-support',
  employerLoginPath: '/employer/login',
  get employerLogin() {
    return addRedirectAfterParam(this.employerLoginPath);
  },
  employerLogout: '/employer/logout',
  employerNewProjectURL: '/employer/projects/new',
  employerNewProjectShowWelcome: '/employer/projects/new?showWelcome=true',
  // new applicant review page
  employerApplicantReview: projectSlug =>
    `/employer/projects/${projectSlug}/applicants?tab=review`,
  employerProjectBid: (projectSlug, bidId, params = '') =>
    `/employer/projects/${projectSlug}/bids/${bidId}${params}`,
  employerProjects: '/employer/projects',
  employerProject: projectId => `/employer/projects/${projectId}`,
  employerProjectUpdate: projectId => `/api/v1/employers/projects/${projectId}`,
  employerProjectReview: projectId => `/employer/projects/${projectId}/review`,
  employerProjectEditPath: projectId => `/employer/projects/${projectId}/edit`,
  employerRecommendedTalent: projectId =>
    `/employer/projects/${projectId}/recommended-talent`,
  employerResources: 'https://work.themomproject.com/resources/home',
  employerSettings: '/employer/settings',
  employerSignupPath: '/employer/signup',
  employerSignupAboutYouPath: '/employer/signup/about-you',
  employerSignupAboutCompanyPath: '/employer/signup/about-company',
  get employerSignup() {
    return addRedirectAfterParam(this.employerSignupPath);
  },
  employerPasswordUpdate: '/employer/update_password',
  faq: '/faq',
  freelancerProPromotionalPage: '/freelancer',
  helpCenter: 'https://help.themomproject.com',
  employerDashboardInviteTeammates:
    '/employer?modal=DashboardInviteTeammatesModal',
  guidelinesForHiring:
    'https://work.themomproject.com/hubfs/Assets/Guidelines%20for%20Hiring%20in%20the%20Gig%20Economy.pdf',
  howItWorksEnterprise:
    'https://work.themomproject.com/how_it_works_enterprise',
  howItWorksSMB: 'https://work.themomproject.com/how_it_works_companies',
  howToAddTeamMembers:
    'https://help.themomproject.com/servicedesk/customer/portal/5/article/15204473',
  hubspotScheduleMeeting:
    'https://meetings.hubspot.com/ashley-frazier/job-flow-creation-support',
  jobs: '/mom/projects',
  join: '/join',
  joinUs: '/join-us',
  nylasScheduler: (interviewSchedulingPageId, appointmentId, email) =>
    `https://schedule.nylas.com/${interviewSchedulingPageId}?appointmentId=${appointmentId}&email=${email}`,
  nylasRescheduler: (rescheduleUrl, appointmentId) =>
    `${rescheduleUrl}?appointmentId=${appointmentId}`,
  pricing: 'https://work.themomproject.com/company-pricing',
  companies: 'https://work.themomproject.com/companies',
  access: 'https://work.themomproject.com/access',
  payPerHire: 'https://work.themomproject.com/pay-per-hire',
  hireFreelancers: 'https://work.themomproject.com/hire-freelancers',
  library: 'https://community.themomproject.com/library',
  project: projectId => `/projects/${projectId}`,
  resumeGuidance:
    'https://community.themomproject.com/the-study/writing-your-resume',
  salaryGuidance:
    'https://community.themomproject.com/the-study/determining-your-salary-range',
  schedulingPages: '/employer/interview_scheduling_pages',
  schedulingPageEdit: id => `/employer/interview_scheduling_pages/${id}/edit`,
  schedulingPageNew: '/employer/interview_scheduling_pages/new',
  talentAppointmentDate: appointmentDateId =>
    `/mom/appointment_dates/${appointmentDateId}`,
  talentCareerStory: '/mom/profile/career',
  talentCloudOnboarding: page => `/mom/talent-cloud-onboarding/${page}`,
  talentCloudsTermsOfService: '/mom/clouds_terms_of_service',
  talentCommunity: 'https://work.themomproject.com/talent-community',
  talentConfirmAppointment: (appointmentId, dateId) =>
    `/mom/appointment/${appointmentId}/dates/${dateId}/confirm`,
  talentDashboard: '/mom',
  talentDemographic: '/mom/demographic_survey',
  talentDemographicSurveyLearnMore:
    'https://help.themomproject.com/servicedesk/customer/portal/5/article/13860918',
  talentEducation: '/mom/profile/education',
  talentGeneratedProfile: id => `/employer/clouds_talent_profile/${id}.pdf`,
  talentGeneratedResume: '/mom/resumes/generated.pdf',
  talentInterviewFeedback: bidSlug => `/mom/interview_feedback/${bidSlug}`,
  talentInterviewScheduled: (appointmentId, viewDetails = false) =>
    `/mom?appointmentId=${appointmentId}&viewDetails=${viewDetails}`,
  talentLoginPath: '/mom/login',
  get talentLogin() {
    return addRedirectAfterParam(this.talentLoginPath);
  },
  talentLogout: '/mom/logout',
  talentMarketplace: '/mom/projects',
  talentMarketplaceWithModal: modal => `/mom/projects/?showModal=${modal}`,
  talentOnboarding: '/mom/onboarding',
  talentPasswordUpdate: '/mom/update_password',
  talentProfile: '/mom/profile',
  talentProfileLink: page => `/mom/profile/${page}`,
  talentProject: projectId => `/mom/projects/${projectId}`,
  talentSettings: '/mom/settings',
  talentSelectInterview: appointmentId =>
    `/mom/appointments/${appointmentId}/interview_confirm`,
  talentSignupPath: '/mom/signup',
  get talentSignup() {
    return addRedirectAfterParam(this.talentSignupPath);
  },
  talentVideoCall: videoCallName => `/mom/interview/${videoCallName}`,
  talentUpdate: '/mom/update',
  thanks: '/mom/thanks',
  werklabs: 'https://werklabs.com/',
  werklabsInsights: 'https://werklabs.com/advisory-services',
  werklabsLeadership: 'https://werklabs.com/thought-leadership',
  werkSpace: 'https://work.themomproject.com/resources/home',
};

export const TALENT_NAV_SECTIONS = {
  dashboard: 'dashboard',
  jobs: 'jobs',
  profile: 'profile',
  resources: 'resources',
  settings: 'settings',
  freelancer_pro: 'freelancer_pro',
};

export const EMPLOYER_OFFSET_TOP = 90;
export const HEADER_OFFSET = 55;

export const DENY_REASONS = [
  ['Explanation not defined / provided', 'not-provided'],
  ['Location of role', 'location'],
  ['Skillset alignment with the role', 'skill-alignment'],
  ['Experience level with industry', 'experience-industry'],
  ['Experience level not a fit for the role’s scope', 'experience-role'],
  ['Counter proposal does not meet role’s scope', 'counter-bid'],
  [
    'Project is filled, closed and/or company already interviewing',
    'project-closed',
  ],
  ['Education area or level not fit for role', 'education-level'],
];

export const TIME_ZONES = [
  {
    name: 'Alaska',
    location: 'America/Anchorage',
    abbreviation: 'AT',
  },
  {
    name: 'Central',
    location: 'America/Chicago',
    abbreviation: 'CT',
  },
  {
    name: 'Eastern',
    location: 'America/New_York',
    abbreviation: 'ET',
  },
  {
    name: 'Hawaii',
    location: 'Pacific/Honolulu',
    abbreviation: 'HT',
  },
  {
    name: 'Mountain',
    location: 'America/Denver',
    abbreviation: 'MT',
  },
  {
    name: 'Mountain Standard',
    location: 'America/Phoenix',
    abbreviation: 'MST',
  },
  {
    name: 'Pacific',
    location: 'America/Los_Angeles',
    abbreviation: 'PT',
  },
];

export const TIMEZONES = [...TIME_ZONES].map(tz => {
  return [`${tz.name} Time (${tz.abbreviation})`, tz.location];
});

export const EMPLOYER_SIGNUP_HEADER = {
  heading:
    'Drive your business forward by connecting with the next generation of highly skilled and diverse talent.',
  description: '',
  id: 'joinUsHero',
};

export const PHONE_NUMBER_MIN = 10;
export const PHONE_NUMBER_MAX = 15;
export const DATE_AND_TIME_LENGTH = 16;

export const USER_TYPES = {
  talent: 'talent',
  employer: 'employer',
};

export const APPOINTMENT_TYPES = [
  ['Phone', 'phone'],
  ['Video', 'video'],
];

// Scheduling page configuration: How long the interview is.
export const SCHEDULING_PAGE_DURATIONS = [
  ['15 minutes', 15],
  ['30 minutes', 30],
  ['45 minutes', 45],
  ['1 hour', 60],
  ['1 hour 30 minutes', 90],
];

export const MINUTES_TO_TEXT = {
  15: '15 minutes',
  30: '30 minutes',
  45: '45 minutes',
  60: '1 hour',
  90: '1 hour 30 minutes',
};

// Scheduling page configuration: How far out to allow users to schedule an interview.
export const SCHEDULING_PAGE_FUTURE_DAYS = [
  ['1 week', 7],
  ['2 weeks', 14],
  ['3 weeks', 21],
  ['1 month', 30],
];

export const FUTURE_DAYS_TO_TEXT = {
  7: '1 week',
  14: '2 weeks',
  21: '3 weeks',
  30: '1 month',
};

export const INTERVIEW_SCHEDULING_PAGE_FORM_TYPES = {
  create: 'create',
  edit: 'edit',
};

export const WORKING_HOURS_ARRAY = [
  ['08:00 AM'],
  ['08:30 AM'],
  ['09:00 AM'],
  ['09:30 AM'],
  ['10:00 AM'],
  ['10:30 AM'],
  ['11:00 AM'],
  ['11:30 AM'],
  ['12:00 PM'],
  ['12:30 PM'],
  ['01:00 PM'],
  ['01:30 PM'],
  ['02:00 PM'],
  ['02:30 PM'],
  ['03:00 PM'],
  ['03:30 PM'],
  ['04:00 PM'],
  ['04:30 PM'],
  ['05:00 PM'],
  ['05:30 PM'],
];

export const MILITARY_TIME_TO_READABLE_HOUR = {
  '08:00': '8:00 AM',
  '08:30': '8:30 AM',
  '09:00': '9:00 AM',
  '09:30': '9:30 AM',
  '10:00': '10:00 AM',
  '10:30': '10:30 AM',
  '11:00': '11:00 AM',
  '11:30': '11:30 AM',
  '12:00': '12:00 PM',
  '12:30': '12:30 PM',
  '13:00': '1:00 PM',
  '13:30': '1:30 PM',
  '14:00': '2:00 PM',
  '14:30': '2:30 PM',
  '15:00': '3:00 PM',
  '15:30': '3:30 PM',
  '16:00': '4:00 PM',
  '16:30': '4:30 PM',
  '17:00': '5:00 PM',
  '17:30': '5:30 PM',
  '18:00': '6:00 PM',
  '18:30': '6:30 PM',
  '19:00': '7:00 PM',
  '19:30': '7:30 PM',
  '20:00': '8:00 PM',
  '20:30': '8:30 PM',
  '21:00': '9:00 PM',
  '21:30': '9:30 PM',
  '22:00': '10:00 PM',
  '22:30': '10:30 PM',
  '23:00': '11:00 PM',
  '23:30': '11:30 PM',
  '24:00': '12:00 AM', // There is no 24:00 but we had a bug and this is backwards compatible to show times if they are stored in Nylas as 24:00
  '24:30': '12:30 AM',
  '00:00': '12:00 AM',
  '00:30': '12:30 AM',
  '01:00': '1:00 AM',
  '01:30': '1:30 AM',
  '02:00': '2:00 AM',
  '02:30': '2:30 AM',
  '03:00': '3:00 AM',
  '03:30': '3:30 AM',
  '04:00': '4:00 AM',
  '04:30': '4:30 AM',
  '05:00': '5:00 AM',
  '05:30': '5:30 AM',
  '06:00': '6:00 AM',
  '06:30': '6:30 AM',
  '07:00': '7:00 AM',
  '07:30': '7:30 AM',
};

export const FULL_HOURS_ARRAY = [
  ['Select', ''],
  ['08:00 AM', '08:00'],
  ['08:30 AM', '08:30'],
  ['09:00 AM', '09:00'],
  ['09:30 AM', '09:30'],
  ['10:00 AM', '10:00'],
  ['10:30 AM', '10:30'],
  ['11:00 AM', '11:00'],
  ['11:30 AM', '11:30'],
  ['12:00 PM', '12:00'],
  ['12:30 PM', '12:30'],
  ['01:00 PM', '13:00'],
  ['01:30 PM', '13:30'],
  ['02:00 PM', '14:00'],
  ['02:30 PM', '14:30'],
  ['03:00 PM', '15:00'],
  ['03:30 PM', '15:30'],
  ['04:00 PM', '16:00'],
  ['04:30 PM', '16:30'],
  ['05:00 PM', '17:00'],
  ['05:30 PM', '17:30'],
  ['6:00 PM', '18:00'],
  ['6:30 PM', '18:30'],
  ['7:00 PM', '19:00'],
  ['7:30 PM', '19:30'],
  ['8:00 PM', '20:00'],
  ['8:30 PM', '20:30'],
  ['9:00 PM', '21:00'],
  ['9:30 PM', '21:30'],
  ['10:00 PM', '22:00'],
  ['10:30 PM', '22:30'],
  ['11:00 PM', '23:00'],
  ['11:30 PM', '23:30'],
  ['12:00 AM', '00:00'],
  ['12:30 AM', '00:30'],
  ['1:00 AM', '01:00'],
  ['1:30 AM', '01:30'],
  ['2:00 AM', '02:00'],
  ['2:30 AM', '02:30'],
  ['3:00 AM', '03:00'],
  ['3:30 AM', '03:30'],
  ['4:00 AM', '04:00'],
  ['4:30 AM', '04:30'],
  ['5:00 AM', '05:00'],
  ['5:30 AM', '05:30'],
  ['6:00 AM', '06:00'],
  ['6:30 AM', '06:30'],
  ['7:00 AM', '07:00'],
  ['7:30 AM', '07:30'],
];

export const SCHEDULING_PAGE_ACTION_TYPE = {
  VIEW: 'view',
  EDIT: 'edit',
  DELETE: 'delete',
};

export const BID_STATUS = {
  draft: 'draft',
  admin_approved: 'admin_approved',
  viewed: 'viewed',
  viewed_by_admin: 'viewed_by_admin',
  accepted: 'accepted',
  denied: 'denied',
  interview: 'interview',
  present: 'present',
  admin_denied: 'admin_denied',
  interview_rescheduling: 'interview_rescheduling',
  interview_scheduled: 'interview_scheduled',
  offer_extended: 'offer_extended',
  withdrawn: 'withdrawn',
  archived: 'archived',
};

export const EMPLOYMENT_STATUS = {
  full_time: 'full_time',
  freelance: 'project_based',
  independent_contractor: 'independent_contractor',
  temp_to_perm: 'maternityship',
  tmp_contractor: 'tmp_contractor',
};

export const UNKNOWN_ERROR_MESSAGE =
  'Oops, something went wrong. Please reach out to us on chat';

export const INTERVIEW_MIN_RANGE = 2;
export const INTERVIEW_MAX_RANGE = 4;

export const POPUP_SHARE_PROPERTIES =
  'width=600,height=400,toolbar=0,menubar=0,location=0,status=1,scrollbars=1,resizable=1,left=0,top=0';

export const SHARE_EMAIL_TEMPLATE =
  "mailto:email@friend.com?subject='Opportunity from The Mom Project'&body=New Opportunity at The Mom Project";

export const PROJECT_STATUS = {
  active: 'active',
  draft: 'draft',
  awaiting_verification: 'awaiting_verification',
  screening: 'screening',
  filled: 'filled',
  cancelled: 'cancelled',
  interviewing: 'interviewing',
  pending_approval: 'pending_approval',
  vms_pause: 'vms_pause',
  pending_removal: 'pending_removal',
  placed: 'placed',
  onboarding: 'onboarding',
  opt_out: 'opt_out',
  candidate_submitted: 'candidate_submitted',
};

export const NOTIFICATION_GROUPS = [
  {
    name: 'marketing',
    description:
      'This includes our monthly newsletter, new program offerings, networking opportunities, and resources for your career.',
  },
  {
    name: 'job_match',
    description:
      'We will notify you when there is a new job posted that matches your skills.',
  },
  {
    name: 'talent_experience_surveys',
    description:
      'Surveys to gather feedback on your experience with The Mom Project and how we can improve.',
  },
];

export const NOTIFICATION_FREQUENCY = [
  {
    name: 'Instantly',
    value: 'instantly',
  },
  {
    name: 'Daily',
    value: 'daily',
  },
  {
    name: 'Weekly',
    value: 'weekly',
  },
  {
    name: 'Never',
    value: 'never',
  },
];

export const NOTIFICATION_FREQUENCIES = {
  instantly: 'instantly',
  daily: 'daily',
  weekly: 'weekly',
  never: 'never',
};

export const MONTHS = [
  ['January', '01'],
  ['February', '02'],
  ['March', '03'],
  ['April', '04'],
  ['May', '05'],
  ['June', '06'],
  ['July', '07'],
  ['August', '08'],
  ['September', '09'],
  ['October', '10'],
  ['November', '11'],
  ['December', '12'],
];

export const MARKETING_LINKS = {
  twitter: 'https://twitter.com/The_Mom_Project',
  instagram: 'https://www.instagram.com/the_mom_project/',
  facebook: 'https://www.facebook.com/themomprojectus/?ref=hl',
  linkedin:
    'https://www.linkedin.com/company/10420321?trk=tyah&trkInfo=clickedVertical%3Acompany%2CclickedEntityId%3A10420321%2Cidx%3A2-1-2%2CtarId%3A1457638901323%2Ctas%3Athe%20mom%20project',
  press: 'https://work.themomproject.com/press-page',
  mediaKit:
    'https://static.themomproject.com/public_assets/Mom%20Project%20Logo.zip',
  blog: 'https://blog.themomproject.com/',
  email: 'mailto:info@themomproject.com',
  events: 'https://community.themomproject.com/events',
  rallyProgram: 'https://work.themomproject.com/letsrally',
  rise: 'https://www.momproject.org',
  workRise: 'https://work.themomproject.com/rise',
  jobDescriptionTemplates:
    'https://work.themomproject.com/job-description-templates',
};

export const COMMUNITY_LINKS = {
  termsOfService: '/terms_of_service',
  guidelines: 'https://work.themomproject.com/community_guidelines',
  privacyPolicy: '/privacy_policy',
};

export const GENERATED_RESUME = 'themomproject-resume.pdf';

export const CAREER_PAUSE_LABEL = 'Career Pause';

export {
  FREE_EMAILS_ARRAY,
  HONEYBADGER_CONFIG,
  MAX_HOURS_PER_WEEK,
  MAX_PROJECT_MONTHS,
  MIN_HOURS_PER_WEEK,
  MIN_HOURLY_RATE,
  MIN_HOURLY_RATE_FOR_CHANNEL,
  MIN_PROJECT_MONTHS,
  MINIMUM_PASSWORD_LENGTH,
  VALID_PROJECT_ACTIONS,
} from 'rails_consts.js.erb';

export const EMPLOYER_FLOW_STEPS = [
  'jobDetails',
  'flexFactors',
  'timingCompensation',
  'review',
];
export const DEFAULT_SCROLL_OPTIONS = {
  behavior: 'smooth',
};
export const FIND_YOUR_PERFECT_FIT_YOUTUBE_URL =
  'https://www.youtube.com/embed/ysjhUOZrPgI';
export const EMPLOYER_GET_STARTED_YOUTUBE_URL =
  'https://www.youtube.com/embed/ysjhUOZrPgI';
export const SUBSCRIBER_EMPLOYER_GET_STARTED_YOUTUBE_URL =
  'https://www.youtube.com/embed/nLLZR-XmP5c';

//Expertises
export const EXPERTISE_MAX_COUNT = 20;
//Emsi has no Skills that are less that 2 characters in length
export const EXPERTISE_MIN_CHAR_LENGTH = 2;

export const CLOUDS_FILTERS_LIST_MAX_COUNT = 5;

export const ITEMS_PER_PAGE = 15;

export const DEFAULT_RADIUS_IN_MILES = 30;

export const APPOINTMENT_TYPES_LABEL = {
  phone: 'phone',
  video: 'video',
};

export const AVAILABLE_APPOINTMENT_TIMES = [
  {
    id: 1,
    time: '08:00',
    displayTime: '08:00 AM',
    to: '08:30',
    displayEndTime: '08:30 AM',
  },
  {
    id: 2,
    time: '08:30',
    displayTime: '08:30 AM',
    to: '09:00',
    displayEndTime: '09:00 AM',
  },
  {
    id: 3,
    time: '09:00',
    displayTime: '09:00 AM',
    to: '09:30',
    displayEndTime: '09:30 AM',
  },
  {
    id: 4,
    time: '09:30',
    displayTime: '09:30 AM',
    to: '10:00',
    displayEndTime: '10:00 AM',
  },
  {
    id: 5,
    time: '10:00',
    displayTime: '10:00 AM',
    to: '10:30',
    displayEndTime: '10:30 AM',
  },
  {
    id: 6,
    time: '10:30',
    displayTime: '10:30 AM',
    to: '11:00',
    displayEndTime: '11:00 AM',
  },
  {
    id: 7,
    time: '11:00',
    displayTime: '11:00 AM',
    to: '11:30',
    displayEndTime: '11:30 AM',
  },
  {
    id: 8,
    time: '11:30',
    displayTime: '11:30 AM',
    to: '12:00',
    displayEndTime: '12:00 PM',
  },
  {
    id: 9,
    time: '12:00',
    displayTime: '12:00 PM',
    to: '12:30',
    displayEndTime: '12:30 PM',
  },
  {
    id: 10,
    time: '12:30',
    displayTime: '12:30 PM',
    to: '13:00',
    displayEndTime: '01:00 PM',
  },
  {
    id: 11,
    time: '13:00',
    displayTime: '01:00 PM',
    to: '13:30',
    displayEndTime: '01:30 PM',
  },
  {
    id: 12,
    time: '13:30',
    displayTime: '01:30 PM',
    to: '14:00',
    displayEndTime: '02:00 PM',
  },
  {
    id: 13,
    time: '14:00',
    displayTime: '02:00 PM',
    to: '14:30',
    displayEndTime: '02:30 PM',
  },
  {
    id: 14,
    time: '14:30',
    displayTime: '02:30 PM',
    to: '15:00',
    displayEndTime: '03:00 PM',
  },
  {
    id: 15,
    time: '15:00',
    displayTime: '03:00 PM',
    to: '15:30',
    displayEndTime: '03:30 PM',
  },
  {
    id: 16,
    time: '15:30',
    displayTime: '03:30 PM',
    to: '16:00',
    displayEndTime: '04:00 PM',
  },
  {
    id: 17,
    time: '16:00',
    displayTime: '04:00 PM',
    to: '16:30',
    displayEndTime: '04:30 PM',
  },
  {
    id: 18,
    time: '16:30',
    displayTime: '04:30 PM',
    to: '17:00',
    displayEndTime: '05:00 PM',
  },
  {
    id: 19,
    time: '17:00',
    displayTime: '05:00 PM',
    to: '17:30',
    displayEndTime: '05:30 PM',
  },
  {
    id: 20,
    time: '17:30',
    displayTime: '05:30 PM',
    to: '18:00',
    displayEndTime: '06:00 PM',
  },
];

// Add all known errors to this structure. Unknown errors will show up here https://app.honeybadger.io/projects/57100/faults/78410515
export const MEDIA_ERRORS = {
  permission_denied: [
    'Permission denied',
    'Permission denied by system',
    'Permission dismissed',
    'The request is not allowed by the user agent or the platform in the current context',
  ],
  not_found: [
    'Requested device not found',
    'Could not start video source',
    'The object can not be found here',
  ],
};

export const MIN_PASSWORD_LENGTH = 10;

export const TALENT_AVAILABILITIES = {
  active: {
    label: 'Actively looking & accepting interviews',
    shortLabel: 'Actively Looking',
    color: colors.green.medium,
    description:
      "You're applying to multiple jobs at this time, and you're excited and ready to interview immediately.",
  },
  casual: {
    label: 'Casually looking',
    shortLabel: 'Casually Looking',
    color: colors.yellow.medium,
    description:
      "You're selectively applying to jobs but not in a rush to find something. You're open to accepting interviews if it's the right fit.",
  },
  unavailable: {
    label: 'Not looking to make a move',
    shortLabel: 'Not Looking',
    color: colors.error,
    description:
      "You're happy in your current situation, and aren't considering a new job right now.",
  },
};

export const PIN_TYPES = {
  membership: 'CloudMembership',
  cloud: 'Cloud',
};

export const TALENT_PROJECT_ACTIONS = {
  interested: 'interested',
  not_interested: 'not_interested',
};

export const CLOUD_TITLE_MAX_COUNT = 10000;
export const CLOUD_TITLE_MIN_CHAR_LENGTH = 2;
export const MIN_SKILLS_BID_REVIEW = 5;
export const MAX_SKILLS_BID_REVIEW = 10;

export const BID_SORT_BY_OPTIONS = {
  submitted_at_desc: 'Newest to oldest',
  submitted_at: 'Oldest to newest',
};

export const BID_SORT_BY_DEFAULT = 'submitted_at_desc';

export const BID_HUMAN_READABLE_STATUS = {
  all: 'All active',
  new: 'New',
  matched_only: 'Matched applicants',
  scheduling: 'Scheduling interviews',
  interviews: 'Upcoming interviews',
  past_interviews: 'Past interviews',
  offer_extended: 'Extended offer',
  denied: 'Denied',
  withdrawn: 'Withdrawn',
};

export const BID_DESCRIPTIONS = {
  saved:
    'Your application has been saved, but not submitted. To review and submit your application, you can go to your dashboard. You can also continue to search for more opportunities.',
};

export const PROJECT_CANCEL_REASONS = [
  { label: 'Hired with The Mom Project', value: 'hired_with_tmp' },
  { label: 'Hired somewhere else', value: 'hired_elsewhere' },
  { label: 'No longer hiring for the role', value: 'role_closed' },
  { label: 'Talent pool was not qualified', value: 'under_qualified_talent' },
  { label: 'Candidate declined offer', value: 'candidate_declined' },
];

export const BID_TRACKING_EVENTS = {
  save: 'save',
  view_profile: 'view_profile',
  interview_scheduled: 'interview_scheduled',
  denied: 'denied',
  offer_extended: 'offer_extended',
};

export const DEFAULT_DEBOUNCE_MILLISECONDS = 500;

export const PREFERRED_EXPERIENCE_LEVEL_VALUES = {
  '1 - 4 years experience': SENIORITY_OPTIONS.JUNIOR,
  '5 - 8 years experience': SENIORITY_OPTIONS.MIDLEVEL,
  '9+ years experience': SENIORITY_OPTIONS.SENIOR,
};

export const PREFERRED_EXPERIENCE_LEVEL_RADIO_GROUP_VALUES = [
  { label: '1 - 4 years experience', value: SENIORITY_OPTIONS.JUNIOR },
  { label: '5 - 8 years experience', value: SENIORITY_OPTIONS.MIDLEVEL },
  { label: '9+ years experience', value: SENIORITY_OPTIONS.SENIOR },
];

// example" Dec 21 2022
export const SHORT_FORMAT_DATE_OPTIONS = {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};

export const REACT_QUERY_STATUSES = {
  LOADING: 'loading',
  ERROR: 'error',
  SUCCESS: 'success',
  INITIAL_DATA: 'initialData',
};

export const CACHE_FOR_ONE_DAY = 1000 * 60 * 60 * 24;
export const CACHE_FOR_ONE_HOUR = 1000 * 60 * 60;

export const UTM_PARAMS = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'referrer',
  'utm_source',
  'utm_term',
];

export const SECURE_LINK_REL = 'noreferrer noopener nofollow';
