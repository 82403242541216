import {
  COMMUNITY_LINKS,
  MARKETING_LINKS,
  TERMS_AND_CONDITIONS,
  URLS,
  SECURE_LINK_REL,
} from 'consts';

export const navItems = {
  publicRoutes: {
    general: {
      label: 'General',
      helptext: 'General',
      children: [
        {
          label: 'Help Center',
          href: URLS.helpCenter,
          target: '_blank',
        },
        {
          label: 'About',
          href: URLS.about,
          target: '_self',
        },
        {
          label: 'FAQs',
          href: URLS.faq,
          target: '_self',
        },
        {
          label: 'Work At TMP',
          href: URLS.careers,
          target: '_self',
        },
        {
          label: 'Press',
          href: MARKETING_LINKS.press,
          target: '_blank',
        },
        {
          label: 'Terms of Service',
          href: TERMS_AND_CONDITIONS,
          target: '_blank',
        },
        {
          label: 'Privacy Policy',
          href: COMMUNITY_LINKS.privacyPolicy,
          target: '_blank',
        },
      ],
    },
    careersAndCommunity: {
      label: 'Careers and Community',
      helptext: 'Moms, dads, and allies',
      children: [
        {
          label: 'Finding Work',
          href: URLS.talentCommunity,
          target: '_self',
        },
        {
          label: 'Mentoring',
          href: MARKETING_LINKS.rallyProgram,
          target: '_self',
        },
        {
          label: 'Upskilling',
          href: MARKETING_LINKS.workRise,
          target: '_self',
        },
        {
          label: 'Advice and Resources',
          href: URLS.careerGuidance,
          target: '_self',
        },
      ],
    },
    smallCompanies: {
      label: 'Small Companies',
      helptext: 'Companies with 1-499 employees',
      children: [
        {
          label: 'How It Works',
          href: URLS.howItWorksSMB,
          target: '_self',
        },
        {
          label: 'Plans and Pricing',
          href: URLS.pricing,
          target: '_self',
        },
        {
          label: 'Tips and Resources',
          href: URLS.werkSpace,
          target: '_self',
        },
      ],
    },
    enterprise: {
      label: 'Enterprise',
      helptext: 'Companies with 500+ employees',
      children: [
        {
          label: 'How It Works',
          href: URLS.howItWorksEnterprise,
          target: '_self',
        },
        {
          label: 'Plans and Pricing',
          href: URLS.pricing,
          target: '_self',
        },
        {
          label: 'WerkLabs Custom Insights',
          href: URLS.werklabsInsights,
          target: '_blank',
        },
        {
          label: 'DEI Thought Leadership',
          href: URLS.werklabsLeadership,
          target: '_blank',
        },
        {
          label: 'Tips and Resources',
          href: URLS.werkSpace,
          target: '_self',
        },
      ],
    },
    about: {
      label: 'About',
      href: URLS.about,
      target: '_self',
    },
  },
  publicRoutes2024: {
    general: {
      label: 'General',
      children: [
        {
          label: 'Help Center',
          href: URLS.customerSupport,
          target: '_blank',
          rel: SECURE_LINK_REL,
        },
        {
          label: 'About',
          href: URLS.about,
        },
        {
          label: 'FAQs',
          href: '/faq',
        },
        {
          label: 'Work At TMP',
          href: '/careers',
        },
        {
          label: 'Press',
          href: MARKETING_LINKS.press,
          target: '_blank',
          rel: SECURE_LINK_REL,
        },
        {
          label: 'Terms of Service',
          href: '/terms_of_service',
          target: '_blank',
          rel: SECURE_LINK_REL,
        },
        {
          label: 'Privacy Policy',
          href: '/privacy_policy',
          target: '_blank',
          rel: SECURE_LINK_REL,
        },
        {
          label: 'California Privacy Notice',
          href: '/ccpa',
          target: '_blank',
          rel: SECURE_LINK_REL,
        },
      ],
    },
    talent: {
      label: 'Talent',
      href: URLS.talentCommunity,
      children: [
        {
          label: 'Finding Work',
          href: URLS.talentCommunity,
        },
        {
          label: 'Mentoring',
          href: MARKETING_LINKS.rallyProgram,
        },
        {
          label: 'Upskilling',
          href: MARKETING_LINKS.workRise,
        },
        {
          label: 'Advice and Resources',
          href: URLS.library,
        },
      ],
    },
    companies: {
      label: 'Companies',
      href: URLS.companies,
      children: [
        {
          label: 'Solutions',
          href: URLS.companies,
        },
        {
          label: 'Access',
          href: URLS.access,
        },
        {
          label: 'Pay per hire',
          href: URLS.payPerHire,
        },
        {
          label: 'Advice and Resources',
          href: URLS.library,
        },
      ],
    },
    about: {
      label: 'About',
      href: URLS.about,
      target: '_self',
    },
  },
};
